<template>
  <sdPageHeader title="Select Theme" />
  <div class="game-container">
    <Main>
      <a-row :gutter="25">
        <a-col
          :xxl="6" :lg="6" :sm="6" :xs="24"
          v-for="theme of themes"
          :key="theme.encrypt_id"
        >
          <GameCardStyle @click="handleThemeSelect(theme)" :style="{ marginBottom: '25px' }">
            <img
              :src="theme.image"
              :alt="theme.name"
            />
            <div class="game-name">{{theme.name}}</div>
            <div v-if="selectedTheme == theme.encrypt_id" class="selected-game">
              <i class="fa fa-check-circle select-icon-class"/>
            </div>
          </GameCardStyle>
        </a-col>
      </a-row>
      <div v-if="!fetching" class="d-flex align-items-center justify-content-center">
        <sdButton @click="goBack" size="small" type="primary">
          Back
        </sdButton>
      </div>
    </Main>
  </div>
</template>

<script>
import _ from "lodash";

import { Main } from "@/view/styled";
import { GameCardStyle } from "./style";

import { API } from "@/services/api";
import Notification from '@/services/Notification';
export default {
  name: "Select-Theme",
  components: {
    Main,
    GameCardStyle,
  },
  data() {
    return {
      selectedTheme: null,
      currentPage: 1,
      perPage: 12,
      themes: [],
      fetching: true,
    };
  },
  computed: {
    adminGameData: function() {
      return this.$store.getters.adminGameData;
    },
    initial: function() {
      return this.$store.getters.initial;
    }
  },
  methods: {
    async fetchThemes() {
      try {
        const params = {
          limit: this.perPage,
          page: this.currentPage,
          game: this.adminGameData.encrypt_id,
        };
        const resData = await API.getThemes({params});
        const { data = {}, status, message = "" } = resData;
        if (status) {
          const { themes } = data?.data ?? {};
          this.themes = themes;
        } else if (!status && message) {
          Notification.show(message, {type: "error", title: "Error"});
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error", title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    async handleThemeSelect(theme) {
      if (this.selectedTheme == theme.encrypt_id) {
        this.selectedTheme = null;
      } else {
        this.selectedTheme = theme.encrypt_id;
        this.$store.commit("setAdminTheme", theme);
        setTimeout(() => {
          this.$router.push({path: 'campaign-defaults'});
        }, 300);
      }
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  beforeMount() {
    if (_.isEmpty(this.adminGameData)) {
      this.$router.go(-1);
      return null;
    }

    this.fetchThemes();
  }
}
</script>