import Styled from 'vue3-styled-components';

const GameCardStyle = Styled.nav`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 5px 20px ${({ theme }) => theme['light-color']}03;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-bottom: 25px;
  p{
    font-size: 13px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme['light-color']};
  }
  img{
    border-radius: 2px;
    height: 157px;
    width: auto;
    resize: none;
  }
`;

export {
  GameCardStyle,
};
